import { useMemo } from 'react'

import prepareSingleAsset from 'src/js/assets/prepareSingleAsset'
import useFetchAssetsData from 'src/js/assets/hooks/useFetchAssetsData'

const useFetchSingleAsset = (ticker) => {
  const { dataObjects, withExchange, withApy, withPrice, withCurrentPrice, err } =
    useFetchAssetsData('useFetchSingleAsset')
  // build assets array.
  const assetObj = useMemo(() => {
    return prepareSingleAsset(
      dataObjects,
      ticker,
      withApy,
      withExchange,
      withPrice,
      withCurrentPrice
    )
  }, [dataObjects, ticker, withApy, withExchange, withPrice, withCurrentPrice])

  // if error, return with log.
  if (err) return console.error(err)

  return assetObj
}

export default useFetchSingleAsset
