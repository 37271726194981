import { format, parse } from 'date-fns'

export const states = {
  oneDay: '1D',
  oneWeek: '1W',
  oneMonth: '1M',
  threeMonths: '3M',
  sixMonths: '6M',
  oneYear: '1Y',
  twoYears: '2Y',
}

const parseDate = (str) => {
  const formats = ['MMM d, yyyy', 'MMM d, yyyy HH:mm']
  for (let i = 0; i < formats.length; i++) {
    const parsed = parse(str, formats[i], new Date())
    if (!isNaN(parsed)) return parsed
  }
  throw new Error('Invalid date format')
}

export const returnTitle = (dateStr, currentState) => {
  const dateObj = parseDate(dateStr)
  let returnStr

  switch (currentState) {
    case states.oneDay:
    case states.oneWeek:
      returnStr = format(dateObj, 'MMM, d hh:mm a')
      break
    case states.oneMonth:
      returnStr = format(dateObj, 'eee MMM, d')
      break
    case states.threeMonths:
    case states.sixMonths:
    case states.oneYear:
    case states.twoYears:
      returnStr = format(dateObj, 'MMM, d')
      break
    default:
      returnStr = format(dateObj, 'hh:mm a')
      break
  }

  return returnStr
}
