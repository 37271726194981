import React from 'react'
import PropTypes from 'prop-types'
import { SizeMe } from 'react-sizeme'
import classNames from 'classnames'
import { differenceInDays, sub } from 'date-fns'
import elv from 'elv'

import { fetchHistoricalPrices } from 'src/js/utils/fetch-prices'
import { returnTitle, states } from 'src/components/assets/Chart/constants.js'

// Styles & Images
import 'static/components/graph/scss/styles.scss'

// F2 Modularized:
const F2 = require('@antv/f2/lib/core')
require('@antv/f2/lib/geom/line')
require('@antv/f2/lib/geom/area')
const Animation = require('@antv/f2/lib/animation/detail')
const Animate = require('@antv/f2/lib/animation/animate').default
const Tooltip = require('@antv/f2/lib/plugin/tooltip')

// Partials:
const PickerButton = ({ copy, alt, selected, onClick }) => (
  <div
    className={classNames('x-components-asset-page-graph__time-selector', {
      'x-components-asset-page-graph__time-selector--selected': selected,
    })}
  >
    <a href="#" onClick={onClick} title={alt}>
      {copy}
    </a>
  </div>
)

// Main component:
class AssetPageGraph extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentState: states.oneWeek,
      data: [],
      currencyHist: {},
      dataCache: {},
      showHourlyDataPickerButtons: true,
    }
  }

  loadData() {
    let dataCache = []

    if (this.state.currentState === states.oneDay) {
      dataCache = this.state.dataCache.hour.slice(-24)
    }

    if (this.state.currentState === states.oneWeek) {
      dataCache = this.state.dataCache.hour
    }

    if (this.state.currentState === states.oneMonth) {
      dataCache = this.state.dataCache.day.slice(
        -differenceInDays(new Date(), sub(new Date(), { months: 1 }))
      )
    }

    if (this.state.currentState === states.threeMonths) {
      dataCache = this.state.dataCache.day.slice(
        -differenceInDays(new Date(), sub(new Date(), { months: 3 }))
      )
    }

    if (this.state.currentState === states.sixMonths) {
      dataCache = this.state.dataCache.day.slice(
        -differenceInDays(new Date(), sub(new Date(), { months: 6 }))
      )
    }

    if (this.state.currentState === states.oneYear) {
      dataCache = this.state.dataCache.day.slice(
        -differenceInDays(new Date(), sub(new Date(), { years: 1 }))
      )
    }

    if (this.state.currentState === states.twoYears) {
      dataCache = this.state.dataCache.day
    }

    if (elv(dataCache)) {
      this.setState({ data: dataCache })

      if (elv(this.chart)) this.chart.changeData(dataCache)
    }
  }

  transformData(records, cacheKey) {
    const data = []

    for (const item of records) {
      const record = {}
      record.date = new Date(item.time * 1000)
      record.close = parseFloat(item.close)

      data.push(record)
    }

    this.setState({
      dataCache: {
        ...this.state.dataCache,
        [cacheKey]: data,
      },
    })

    const hourly = this.state.dataCache.hour

    if (!hourly.length) {
      this.setState({
        showHourlyDataPickerButtons: false,
        currentState: states.oneYear,
      })
    }

    this.loadData()
  }

  async fetchData(granularity) {
    try {
      if (!elv(this.state.dataCache[granularity])) {
        const prices = await fetchHistoricalPrices({
          granularity,
          assets: [this.props.fromSymbol],
        })

        if (!prices[this.props.fromSymbol]) return

        this.transformData(prices[this.props.fromSymbol].USD, granularity)
      }

      this.loadData()
    } catch (e) {
      console.error(e)
    }
  }

  componentDidMount() {
    this.fetchData('hour')
    this.fetchData('day')
  }

  handle1DClicked(e) {
    e.preventDefault()
    this.chart.scale('date', { type: 'timeCat', tickCount: 7, mask: 'MMM, D' })
    this.setState({ currentState: states.oneDay }, () => {
      this.fetchData('hour')
    })
  }

  handle1WClicked(e) {
    e.preventDefault()
    this.chart.scale('date', { type: 'timeCat', tickCount: 3, mask: 'MMM, D' })
    this.setState({ currentState: states.oneWeek }, () => {
      this.fetchData('hour')
    })
  }

  handle1MClicked(e) {
    e.preventDefault()
    this.chart.scale('date', { type: 'timeCat', mask: 'MMM, D', tickCount: 3, nice: true })
    this.setState({ currentState: states.oneMonth }, () => {
      this.fetchData('day')
    })
  }

  handle3MClicked(e) {
    e.preventDefault()
    this.chart.scale('date', { type: 'timeCat', mask: 'MMM, D', tickCount: 3, nice: true })
    this.setState({ currentState: states.threeMonths }, () => {
      this.fetchData('day')
    })
  }

  handle6MClicked(e) {
    e.preventDefault()
    this.chart.scale('date', { type: 'timeCat', mask: 'MMM, D', tickCount: 3, nice: true })
    this.setState({ currentState: states.sixMonths }, () => {
      this.fetchData('day')
    })
  }

  handle1YClicked(e) {
    e.preventDefault()
    this.chart.scale('date', { type: 'timeCat', mask: 'MMM, D', tickCount: 7, nice: true })
    this.setState({ currentState: states.oneYear }, () => {
      this.fetchData('day')
    })
  }

  handle2YClicked(e) {
    e.preventDefault()
    this.chart.scale('date', { type: 'timeCat', mask: 'MMM, D', tickCount: 7, nice: true })
    this.setState({ currentState: states.twoYears }, () => {
      this.fetchData('day')
    })
  }

  drawChart() {
    if (elv(this.chart)) this.chart.destroy()

    if (typeof document !== `undefined`) {
      Animate.registerAnimation('lineUpdate', function (updateShape, animateCfg) {
        const cacheShape = updateShape.get('cacheShape')
        const cacheAttrs = cacheShape.attrs
        const geomType = cacheShape.geomType

        const oldPoints = cacheAttrs.points
        const newPoints = updateShape.attr('points')

        const oldLength = oldPoints.length
        const newLength = newPoints.length
        let deltaLength = geomType === 'area' ? (oldLength - newLength) / 2 : oldLength - newLength

        if (deltaLength > 0) {
          const firstPoint = newPoints[0]
          const lastPoint = newPoints[newPoints.length - 1]

          for (let i = 0; i < deltaLength; i++) {
            newPoints.splice(0, 0, firstPoint)
          }

          if (geomType === 'area') {
            for (let j = 0; j < deltaLength; j++) {
              newPoints.push(lastPoint)
            }
          }
        } else {
          deltaLength = Math.abs(deltaLength)
          const firstPoint1 = oldPoints[0]
          const lastPoint1 = oldPoints[oldPoints.length - 1]

          for (let k = 0; k < deltaLength; k++) {
            oldPoints.splice(0, 0, firstPoint1)
          }

          if (geomType === 'area') {
            for (let p = 0; p < deltaLength; p++) {
              oldPoints.push(lastPoint1)
            }
          }

          cacheAttrs.points = oldPoints
        }
        updateShape.attr(cacheAttrs)
        updateShape.animate().to({
          attrs: { points: newPoints },
          duration: 300,
          easing: animateCfg.easing,
        })
      })

      this.chart = new F2.Chart({
        id: `x_chart`,
        height: this.props.height,
        pixelRatio: window.devicePixelRatio,
        padding: [
          this.props.topMargin,
          this.props.rightMargin,
          this.props.bottomMargin,
          this.props.leftMargin,
        ],
        plugins: [Tooltip, Animation],
      })

      const formatterCurrency = new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 5,
      })

      this.chart.appendPadding = 0
      this.chart.tooltip({
        triggerOn: ['touchstart', 'touchmove', 'mousemove'],
        showTitle: true,
        showItemMarker: false,
        titleStyle: {
          fill: '#6D6D6D',
          textAlign: 'center',
          display: 'block',
          fontSize: '13',
        },
        valueStyle: {
          fill: '#fff',
          textAlign: 'center',
          display: 'block',
          fontSize: '15',
        },
        tooltipMarkerStyle: {
          fill: '#1A1C1F',
          stroke: this.props.colorStart,
          strokeOpacity: 1,
          lineWidth: 2,
        },
        background: {
          radius: 4,
          fill: '#0D0D0D',
          outline: '#0D0D0D',
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          stroke: '#0D0D0D',
          padding: [10, 10],
          lineWidth: 0,
        },
        onShow: function onShow(ev) {
          const items = ev.items
          items[0].title = returnTitle(items[0].origin.date, this.state.currentState)
          items[0].name = null
          items[0].value = '   ' + formatterCurrency.format(items[0].value) + ' USD   '

          items.splice(1)
        }.bind(this),
        showCrosshairs: true,
        offsetY: 0,
        offsetX: 0,
        crosshairsStyle: { stroke: '#454545', lineWidth: 1 },
      })

      this.chart
        .line({ sortable: false })
        .position('date*close')
        .color(`l(0) 0:${this.props.colorStart} 1:${this.props.colorStop}`)
        .style({ lineWidth: 1.9 })
        .animate({ update: { animation: 'lineUpdate' } })

      this.chart
        .area({ sortable: false })
        .position('date*close')
        .color('l(90) 0:' + this.props.colorStart + ' 1:#1A1C1F')
        .animate({ update: { animation: 'lineUpdate' } })

      this.chart.scale('close', {
        tickCount: 6,
        formatter: function (val) {
          let returnVal = val.toFixed(2)
          if (val < 1) returnVal = val.toFixed(5)
          return returnVal
        },
      })

      this.chart.scale('date', { type: 'timeCat', mask: 'MMM, D', tickCount: 3 })
      this.chart.axis('date', {
        labelOffset: 10,
        label: function label(text, index, total) {
          const config = {}
          if (index === 0) {
            config.textAlign = 'left'
          } else if (index === total - 1) {
            config.textAlign = 'right'
          }

          return config
        },
        line: {
          stroke: 'rgba(255,255,255,.08)',
          lineWidth: 1,
        },
      })

      this.chart.source(this.state.data)

      this.props.showYAxis
        ? this.chart.axis('close', {
            labelOffset: 5,
            grid: {
              type: 'line',
              top: true,
              stroke: 'rgba(255,255,255,.08)',
              lineWidth: 1,
              lineDash: [0],
            },
          })
        : this.chart.axis('close', {
            label: this.props.showYAxis,
            labelOffset: 5,
            grid: {
              type: 'line',
              top: true,
              stroke: 'rgba(255,255,255,.08)',
              lineWidth: 1,
              lineDash: [0],
            },
          })

      this.chart.render()
    }
  }

  updateWindow(size) {
    if (!elv(size.width)) return

    if (typeof document !== `undefined`) {
      const domE = document.getElementsByTagName(`canvas`)[0]

      if (elv(domE) && domE.width !== Math.floor(size.width) * window.devicePixelRatio) {
        domE.width = size.width

        this.drawChart()
      }
    }

    return <div />
  }

  renderPicker() {
    if (this.props.showPicker) {
      return (
        <div className="x-components-asset-page-graph__time-selectors">
          {this.state.showHourlyDataPickerButtons && (
            <>
              <PickerButton
                copy={states.oneDay}
                alt="One Day"
                selected={this.state.currentState === states.oneDay}
                onClick={this.handle1DClicked.bind(this)}
              />
              <PickerButton
                copy={states.oneWeek}
                alt="One Week"
                selected={this.state.currentState === states.oneWeek}
                onClick={this.handle1WClicked.bind(this)}
              />
            </>
          )}
          <PickerButton
            copy={states.oneMonth}
            alt="One Month"
            selected={this.state.currentState === states.oneMonth}
            onClick={this.handle1MClicked.bind(this)}
          />
          <PickerButton
            copy={states.threeMonths}
            alt="Three Months"
            selected={this.state.currentState === states.threeMonths}
            onClick={this.handle3MClicked.bind(this)}
          />
          <PickerButton
            copy={states.sixMonths}
            alt="Six Months"
            selected={this.state.currentState === states.sixMonths}
            onClick={this.handle6MClicked.bind(this)}
          />
          <PickerButton
            copy={states.oneYear}
            alt="One Year"
            selected={this.state.currentState === states.oneYear}
            onClick={this.handle1YClicked.bind(this)}
          />
          <PickerButton
            copy={states.twoYears}
            alt="Two Years"
            selected={this.state.currentState === states.twoYears}
            onClick={this.handle2YClicked.bind(this)}
          />
        </div>
      )
    }

    return null
  }

  render() {
    return (
      <div>
        <div className="mb-3">{this.renderPicker()}</div>
        <SizeMe>{({ size }) => this.updateWindow(size)}</SizeMe>
        <canvas id="x_chart" />
      </div>
    )
  }
}

// Main component props:
AssetPageGraph.propTypes = {
  fromSymbol: PropTypes.string,
  toSymbol: PropTypes.string,
  showPicker: PropTypes.bool,
  showYAxis: PropTypes.bool,
  colorStart: PropTypes.string,
  colorStop: PropTypes.string,
  height: PropTypes.number,
  topMargin: PropTypes.number,
  rightMargin: PropTypes.number,
  bottomMargin: PropTypes.number,
  leftMargin: PropTypes.number,
}

AssetPageGraph.defaultProps = {
  showPicker: true,
  showYAxis: true,
  height: 230,
  leftMargin: 50,
  rightMargin: 20,
  bottomMargin: 20,
  topMargin: 60,
}

export default AssetPageGraph
