import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { get } from 'lodash'
import millify from 'millify'

import { fetchHistoricalPrices, fetchCurrentPrice, fetchTickerData } from '../js/utils/fetch-prices'

import HistGraph from './asset-page-graph.js'

import { CURRENCY } from 'src/constants'

import formatCurrency from '../js/utils/formatCurrency'

import 'static/components/stats/scss/styles.scss'

const AssetPageQuoteStats = ({ symbol, colorStart, colorStop }) => {
  const [price, setPrice] = useState('...')
  const [percentageChange, setPercentageChange] = useState(0)
  const [percentageChangeStr, setPercentageChangeStr] = useState('...')
  const [marketCap, setMarketCap] = useState('...')
  const [twentyFourHourVolume, setTwentyFourHourVolume] = useState('...')
  const [fiftyTwoWeekLow, setFiftyTwoWeekLow] = useState('...')
  const [fiftyTwoWeekHigh, setFiftyTwoWeekHigh] = useState('...')
  const [twentyFourHourLow, setTwentyFourHourLow] = useState('...')
  const [twentyFourHourHigh, setTwentyFourHourHigh] = useState('...')

  useEffect(() => {
    let isMounted = true

    const fetchPrice = async () => {
      const prices = await fetchCurrentPrice([symbol])
      const assetPrice = get(prices, [symbol, CURRENCY])

      if (assetPrice) {
        const formattedPrice = formatCurrency({ number: assetPrice, currency: CURRENCY })

        if (isMounted) {
          setPrice(formattedPrice)
        }
      }
    }

    const fetchTicker = async () => {
      let tickerData = await fetchTickerData([symbol])
      tickerData = get(tickerData, [symbol, CURRENCY])

      if (tickerData && isMounted) {
        setPercentageChange(tickerData.c24h)
        setPercentageChangeStr(`${tickerData.c24h.toFixed(2)}%`)
        setMarketCap(tickerData.mc)
        setTwentyFourHourVolume(tickerData.v24h)
      }
    }

    const fetchYearHistoricalPrices = async () => {
      let yearHistoricalPrices = await fetchHistoricalPrices({
        assets: [symbol],
        granularity: 'day',
        limit: 365,
      })
      yearHistoricalPrices = get(yearHistoricalPrices, [symbol, CURRENCY])

      if (!yearHistoricalPrices) return

      let yearLow = yearHistoricalPrices[0].close
      let yearHigh = yearHistoricalPrices[0].close
      yearHistoricalPrices.forEach(({ close }) => {
        yearLow = Math.min(close, yearLow)
        yearHigh = Math.max(close, yearHigh)
      })

      const formattedYearLow = formatCurrency({ number: yearLow, currency: CURRENCY })
      const formattedYearHigh = formatCurrency({ number: yearHigh, currency: CURRENCY })

      if (isMounted) {
        setFiftyTwoWeekLow(formattedYearLow)
        setFiftyTwoWeekHigh(formattedYearHigh)
      }
    }

    const fetchDayHistoricalPrices = async () => {
      let dayHistoricalPrices = await fetchHistoricalPrices({
        assets: [symbol],
        granularity: 'hour',
        limit: 24,
      })
      dayHistoricalPrices = get(dayHistoricalPrices, [symbol, CURRENCY])

      if (!dayHistoricalPrices) return

      let oneDayLow = dayHistoricalPrices[0].close
      let oneDayHigh = dayHistoricalPrices[0].close
      dayHistoricalPrices.forEach(({ close }) => {
        oneDayLow = Math.min(close, oneDayLow)
        oneDayHigh = Math.max(close, oneDayHigh)
      })

      const formattedOneDayLow = formatCurrency({ number: oneDayLow, currency: CURRENCY })
      const formattedOneDayHigh = formatCurrency({ number: oneDayHigh, currency: CURRENCY })

      if (isMounted) {
        setTwentyFourHourLow(formattedOneDayLow)
        setTwentyFourHourHigh(formattedOneDayHigh)
      }
    }

    // Fetch quote stats data
    fetchPrice()
    fetchTicker()
    fetchYearHistoricalPrices()
    fetchDayHistoricalPrices()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className="container no-gutters x-components-asset-page-stats__container">
      <div className="row no-gutters mt-5 x-components-asset-page-stats__container-row">
        <div className="x-components-asset-page-stats__currencyNameContainer x-components-asset-page-stats__price">
          <div className="x-components-asset-page-stats__currencyName">{price}</div>
        </div>

        <div className="x-components-asset-page-stats__currencyChangeContainer">
          <div
            className={classNames('x-components-asset-page-stats__change', {
              'x-components-asset-page-stats__changeGreen': percentageChange > 0,
              'x-components-asset-page-stats__changeRed': percentageChange < 0,
            })}
          >
            <span className="x-components-asset-page-stats__change-percent">
              {percentageChangeStr}
            </span>
          </div>
          <div className="x-components-asset-page-stats__currencySymbolSubText">24h change</div>
        </div>
      </div>

      <div className="row no-gutters x-components-asset-page-stats__graph">
        <div className="col-12">
          <HistGraph
            fromSymbol={symbol}
            toSymbol={CURRENCY}
            colorStart={colorStart}
            colorStop={colorStop}
          />
        </div>
      </div>

      <div className="row mt-5 x-components-asset-page-stats__bar">
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">
            Market Cap
            {/* Tooltip - enable when needed */}
            {/* <div className="x-components-asset-page-stats__tooltip">
              <div className="x-components-asset-page-stats__tooltip-text">
                Lorem ipsum dolor sit amet consedur abet achtum dolore abento astro
              </div>
            </div> */}
          </div>
          <div className="x-components-asset-page-stats__stat">
            {millify(Number(marketCap) || 0)} {CURRENCY}
          </div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">24hr Volume</div>
          <div className="x-components-asset-page-stats__stat">
            {millify(Number(twentyFourHourVolume) || 0)} {CURRENCY}
          </div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">24hr Low</div>
          <div className="x-components-asset-page-stats__stat">{twentyFourHourLow}</div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">24hr High</div>
          <div className="x-components-asset-page-stats__stat">{twentyFourHourHigh}</div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">52 weeks Low</div>
          <div className="x-components-asset-page-stats__stat">{fiftyTwoWeekLow}</div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">52 weeks High</div>
          <div className="x-components-asset-page-stats__stat">{fiftyTwoWeekHigh}</div>
        </div>
      </div>
    </div>
  )
}

export default AssetPageQuoteStats
