const formatCurrency = ({ number, currency, decimals }) => {
  const decimalPlaces = decimals || (number >= 10 ? 2 : 5)

  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  })
  return formatter.format(number)
}

export default formatCurrency
