import React, { useState, useEffect } from 'react'
import { SizeMe } from 'react-sizeme'

import AssetPageQuoteStats from 'src/components/asset-page-quote-stats'

import useFetchSingleAsset from 'js/assets/hooks/useFetchSingleAsset'
import { getUrlParams } from 'src/utils'

// Styles & Images
import 'static/asset-chart/scss/styles.scss'

// Main component:
const AssetPage2Graph = () => {
  const [options, setOptions] = useState({})

  useEffect(() => {
    const params = getUrlParams(['asset', 'backgroundColor'])
    setOptions(params)

    if (typeof window !== 'undefined') {
      window.top.postMessage({ type: 'CONTENT_RENDERED' })
    }
  }, [])

  const { asset, backgroundColor } = options

  // fetch single asset.
  const data = useFetchSingleAsset(asset || 'BTC')
  const [colorStart, colorStop] = data?.colors?.gradient

  if (!data) return null

  return (
    <div className="x-page-asset-chart">
      <div className="x" style={{ backgroundColor }}>
        <SizeMe>
          {() => {
            /*
             * SizeMe would return height, we want scrollHeight
             * We still use SizeMe as a resize trigger
             */
            if (typeof window !== 'undefined') {
              window.top.postMessage({ type: 'UPDATE_GRAPH_HEIGHT' }, '*')
            }

            return (
              <AssetPageQuoteStats
                colorStart={colorStart}
                colorStop={colorStop}
                symbol={data?.ticker}
              />
            )
          }}
        </SizeMe>
      </div>
    </div>
  )
}

export default AssetPage2Graph

// <head> component:
export const Head = () => (
  <>
    <title>Price Chart</title>
    <meta name="robots" content="noindex" />
    <meta name="googlebot-news" content="noindex" />
    <meta httpEquiv="cache-control" content="no-cache, no-store, must-revalidate" />
    <meta httpEquiv="expires" content="0" />
    <meta httpEquiv="pragma" content="no-cache" />
  </>
)
